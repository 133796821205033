import React from "react";

import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Co2Icon from "@mui/icons-material/Co2";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import {
  Datagrid,
  DatagridConfigurable, DateField,
  FunctionField,
  List, ListContextProvider, ReferenceField, SimpleShowLayout,
  TextField, useList, useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { stoveConnectionTypeFilter, useStoveFilters } from "../../components/filter/stove-filters";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import {
  AllStoveInactivationReason,
  ConnectionType, Device, DeviceStatus,
  StoveModel, StoveStatus
} from "../../utils/commons";
import {
  countryNameRenderer,
  customerRenderer, deviceStatusIcons, enumIconRenderer,
  enumRenderer, stoveStatusIcons
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { ImportDeviceDataButton } from "./bulk-actions/ImportDeviceDataButton";
import StoveBulkActions from "./bulk-actions/StoveBulkActions";
import { FullStove } from "./StoveShow";

const renderUnlockedUntil = (record: Device) => {
  const momentTimeUnlockedUntil = moment(record.timeUnlockedUntil);
  const timeUnlockedUntil = momentTimeUnlockedUntil.toDate().toLocaleDateString();
  const isLocked = momentTimeUnlockedUntil.isBefore(moment());

  return (
    <Box>
      {record.newTimeUnlockedUntil &&
            <Tooltip title={record.timeUnlockedUntil ? timeUnlockedUntil : "None" }>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ opacity: 0.6 }}>
                  {moment(record.newTimeUnlockedUntil).toDate().toLocaleDateString()}
                </Box>
                <CloudSyncIcon color="primary" fontSize="small"/>
              </Box>
            </Tooltip>
      }
      {record.timeUnlockedUntil && !record.newTimeUnlockedUntil ?
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {timeUnlockedUntil}
          {isLocked ? <LockIcon color="error" fontSize="small"/> : <LockOpenIcon color="success" fontSize="small"/>}
        </Box> :
        null}
    </Box>
  );
};

const renderCustomer = (record: FullStove, hasAccess: boolean) => {
  const customer = record.customer;
  return (hasAccess || !customer) ?
    customerRenderer(customer) :
    `${customer.firstName} ${customer.lastName}`;
};

const StovesList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY]);
  const currentDeviceRecord = useRecordContext();
  const sortedStoves = currentDeviceRecord?.stoves?.sort((a: FullStove, b: FullStove) => (a.id > b.id ? -1 : 1));
  const listContext = useList({ data: sortedStoves });

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <SimpleShowLayout>
          <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="nativeId" label="Stove UID" sortable={false}/>
              <FunctionField
                label="Customer"
                render={(record: FullStove) => renderCustomer(record, hasAccess)}
              />
              <FunctionField label="Connection Type"
                render={(record: FullStove) => enumRenderer(record.connectionType, ConnectionType)}/>
              {
                hasAccess &&
                  <ReferenceField label="Company" reference={Resources.Companies} source="companyId" sortable={false}>
                    <TextField source="name"/>
                  </ReferenceField>
              }
              <FunctionField label="Status"
                render={(record: FullStove) => enumIconRenderer(record.status, StoveStatus, stoveStatusIcons)}/>
              <FunctionField label="Repossession Reason"
                source="inactivationReason"
                render={(record: FullStove) => enumRenderer(record.inactivationReason, AllStoveInactivationReason)}/>
              <DateField label="Time created" source="timeCreated" showTime/>
              <DateField label="Time repossessed" source="timeInactivated" showTime sortable={false}/>
            </Datagrid>
          </ListContextProvider>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const stoveDeviceIdRenderer = (record: Device) => {
  const stove = findActiveStove(record);
  if (!stove) return <Link to={`/stoves/devices/${record.id}/show`}>
    {record.deviceId}
  </Link>;

  const nftIcon = stove.nftEnabled ?
    <Tooltip title="This stove is enabled for NFT and working hard to reduce carbon dioxide emissions!">
      <Co2Icon color="info" fontSize="small"/>
    </Tooltip> :
    null;

  return <Box
    sx={{
      opacity: stove.status === "INACTIVE" ? 0.6 : 1,
      display: "flex",
      alignItems: "center",
      gap: 1
    }}>
    <Link to={`/stoves/devices/${record.id}/show`}>
      {record.deviceId}
    </Link>{nftIcon}
  </Box>;
};

const findLastStove = (record: Device) => {
  return record.stoves
    .sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0];
};

const findActiveStove = (record: Device) => record.stoves.find((stove) => stove.status === "ACTIVE");
export const StoveList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY]);
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  const filters = [
    ...useCustomerFilters({}),
    ...useStoveFilters({
    }),
    stoveConnectionTypeFilter(),
    ...useDateRangeFilters({
      lowerBoundSource: "timeUnlockedUntilGreaterThan",
      lowerBoundLabel: "Unlocked Until (>=)",
      upperBoundSource: "timeUnlockedUntilLessThan",
      upperBoundLabel: "Unlocked Until (<=)"
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "timeInactivatedGreaterThan",
      lowerBoundLabel: "Time repossessed (>=)",
      upperBoundSource: "timeInactivatedLessThan",
      upperBoundLabel: "Time repossessed (<=)"
    })
  ];
  return (<List
    filters={filters}
    actions={
      <PresetListActions selectColumnsEnabled
        additionalExportRoles={[UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN]}>
        <ImportDeviceDataButton/>
      </PresetListActions>
    }
    pagination={<DefaultPagination/>}
    perPage={25}
    empty={false}
    exporter={false}
  >      <DatagridConfigurable
      bulkActionButtons={<StoveBulkActions/>}
      omit={[ "ixoAddress", "did", "nftId", "nftStoveCollection"]}
      expand={<StovesList/>}
      rowClick="show">
      <FunctionField
        label="Device ID"
        render={(record: Device) => stoveDeviceIdRenderer(record)}
      />

      {isAdmin &&
        <FunctionField
          label="Nft Collection Name"
          source="nftStoveCollection"
          render={(record:Device) => record.nftStoveCollection?.name}
          sortable={false}
        />
      }
      {isAdmin && <TextField label="Nft Id" source="nftId" sortable={false}/>}
      {isAdmin && <TextField label="DID" source="did" sortable={false}/>}
      {isAdmin && <TextField label="Ixo Address" source="ixoAddress" sortable={false}/>}
      <TextField source="nativeId"
        sortable={false}
        label="Stove UID"
      />
      <FunctionField label="Model"
        render={(record: Device) => enumRenderer(record.model, StoveModel)}/>
      <FunctionField
        label="Status"
        render={(record: Device) => enumIconRenderer(record.status, DeviceStatus, deviceStatusIcons)}/>
      <FunctionField
        label="Connection Type"
        render={(record: Device) => {
          const lastStove = findLastStove(record);
          return lastStove ? enumRenderer(lastStove.connectionType, ConnectionType) : "";
        }}
      />
      <FunctionField
        label="Customer"
        render={(record: Device) => {
          const activeStove = findActiveStove(record);
          return activeStove ? renderCustomer(activeStove, hasAccess) : "";
        }}
      />
      {hasAccess && <FunctionField
        label="Company"
        render={(record: Device) => {
          const companyId = record?.stoves?.find((stove) => stove.companyId)?.companyId;
          return companyId ? (
            <ReferenceField label="Company" reference={Resources.Companies} record={{ id: companyId }} source="id" sortable={false}>
              <TextField source="name"/>
            </ReferenceField>
          ) : "";
        }}
      />}
      <FunctionField label="Country"
        render={(record: Device) => {
          const activeStove = findActiveStove(record);
          return activeStove ? countryNameRenderer(activeStove) : "";
        }}/>
      <FunctionField
        label="Unlocked until"
        sortable={true}
        sortBy={"timeUnlockedUntil"}
        render={(record: Device) => renderUnlockedUntil(record)}
      />
      <DateField label="Time registered" source="timeCreated" showTime sortable={true}/>
    </DatagridConfigurable>
  </List>);
};