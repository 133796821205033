import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Button, useListContext, useDataProvider } from "react-admin";

const ExportTransactionButton = () => {
  const { filterValues, sort } = useListContext();
  const dataProvider = useDataProvider();

  const onClick = () => {
    return dataProvider.exportFile("customers/transactions", filterValues, sort);
  };

  return (
    <Button
      onClick={onClick}
      label="Export Transactions"
      startIcon={<DownloadIcon />}
    />
  );
};

export default ExportTransactionButton;
