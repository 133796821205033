import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { maxLength } from "ra-core";
import {
  AutocompleteInput,
  minValue,
  NumberInput,
  required, SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar, useDataProvider, useNotify
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

import { CollectionPartner } from "../../../components/collections/types";
import {
  AddStockEventType,
  StockEventAdjustmentReason,
  StockEventItem
} from "../../../components/stock-events/types";
import { StockItem } from "../../../components/stock-item/types";
import { StockItemType } from "../../../components/stock/types";
import { Resources } from "../../../resources";
import { CollectionPartnerGroupedSelect } from "./CollectionPartnerGroupedSelect";

type ToolbarProps = {
  isSaveButtonDisabled: boolean,
  handleClose: () => void
};

type CreateStockEventDialogProps = {
  onClose: () => void,
  onConfirm: (data: any) => void,
  isProcessing: boolean,
  isOpened: boolean,
  collectionPartners: CollectionPartner[]
};

type CreateStockEventFieldsProps = {
  collectionPartners: CollectionPartner[]
};

type StockItemInputProps = {
  index: number,
  availableStockItems: StockItem[],
  selectedStockItems: StockEventItem[],
  setStockItems: (stockItems: StockEventItem[]) => void
};

type QuantityInputProps = {
  index: number,
  selectedStockItems: StockEventItem[],
  setStockItems: (stockItems: StockEventItem[]) => void
};

const ADJUSTMENT_REASONS_NOT_ALLOWED_FOR_MANUAL_CREATION =
    [StockEventAdjustmentReason.CANCELLATION,
      StockEventAdjustmentReason.WRONG_EVENT,
      StockEventAdjustmentReason.DOUBLE_EVENT,
      StockEventAdjustmentReason.OTHER_CANCELLATION];

const STOCK_EVENT_TYPES_FOR_MANUAL_CREATION =
    Object.entries(AddStockEventType)
      .map(([key, value]) => ({ id: key, name: value }));

const STOCK_EVENT_ADJUSTMENT_REASONS_FOR_MANUAL_CREATION =
    Object.entries(StockEventAdjustmentReason)
      .filter(([, value]) => !ADJUSTMENT_REASONS_NOT_ALLOWED_FOR_MANUAL_CREATION.includes(value))
      .map(([key, value]) => ({ id: key, name: value }));

const StockEventCreationToolbar: React.FC<ToolbarProps> = ({ handleClose, isSaveButtonDisabled }) => {
  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item><SaveButton disabled={isSaveButtonDisabled}/></Grid>
        <Grid item>
          <Button onClick={handleClose} variant="outlined">
              Cancel
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const AdjustmentReasonInput = () => {
  const type = useWatch({ name: "type" });

  return type === "ADJUSTMENT_INCREASE" || type === "ADJUSTMENT_DECREASE" ? (
    <SelectInput source="adjustmentReason" fullWidth validate={[required()]} choices={STOCK_EVENT_ADJUSTMENT_REASONS_FOR_MANUAL_CREATION}/>
  ) : null;
};

const QuantityInput: React.FC<QuantityInputProps> = ({ selectedStockItems, setStockItems, index }) => {
  const type = useWatch({ name: "type" });
  const quantityMinValue = type === "STOCK_COUNT" ? 0 : 1;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newQuantity = Number(event.target.value);
    const updatedStockItems = selectedStockItems.map((item, i) =>
      i === index ? { ...item, quantity: newQuantity } : item
    );
    setStockItems(updatedStockItems);
  };
  return (
    <NumberInput
      label="Quantity"
      name={`stockItems[${index}].quantity`}
      source={`stockItems[${index}].quantity`}
      validate={[required(), minValue(quantityMinValue)]}
      fullWidth
      onChange={handleChange}
    />
  );
};

const StockItemInput: React.FC<StockItemInputProps > = ({
  availableStockItems,
  selectedStockItems,
  setStockItems,
  index }) => {
  const handleChange = (event: React.ChangeEvent<{ value: number }>) => {
    const newStockItemId = Number(event);
    const updatedStockItems = selectedStockItems.map((item, i) =>
      i === index ? { ...item, stockItemId: newStockItemId } : item
    );
    setStockItems(updatedStockItems);
  };
  const selectedCollectionPartnerType = useWatch({ name: "selectedCollectionPartnerType" });
  const selectedRelatedCollectionPartnerType = useWatch({ name: "selectedRelatedCollectionPartnerType" });
  const selectedRelatedCollectionPartnerCountry = useWatch({ name: "selectedRelatedCollectionPartnerCountry" });
  const selectedCollectionPartnerCountry = useWatch({ name: "selectedCollectionPartnerCountry" });
  const selectedType = selectedStockItems[index]?.stockItemType;

  const filteredStockItems = availableStockItems.filter(
    (stockItem) => stockItem.type === selectedType
  ).filter((stockItem) =>
    stockItem.availableFor.includes(selectedCollectionPartnerType) &&
      (stockItem.restrictionCountries.length === 0
          || stockItem.restrictionCountries.includes(selectedCollectionPartnerCountry)) &&
      (!selectedRelatedCollectionPartnerType
          || stockItem.availableFor.includes(selectedRelatedCollectionPartnerType)) &&
      (!selectedRelatedCollectionPartnerCountry ||
          (stockItem.restrictionCountries.length === 0
              || stockItem.restrictionCountries.includes(selectedRelatedCollectionPartnerCountry)))
  );
  return (<AutocompleteInput
    size="small"
    label="Stock Item"
    source={`stockItems[${index}].stockItemId`}
    choices={filteredStockItems
      .map((stockItem) => ({ id: stockItem.id, name: stockItem.name }))
    }
    fullWidth
    onChange={handleChange}
  />);
};

const StockItemTypeInput: React.FC<{
  index: number,
  stockItems: StockEventItem[],
  setStockItems: (items: StockEventItem[]) => void
}> = ({
  index,
  stockItems,
  setStockItems
}) => {
  const handleChange = (event: any) => {
    const newType = event.target.value;
    const updatedStockItems = stockItems.map((item, i) =>
      i === index ? { ...item, stockItemType: newType, stockItemId: 0 } : item
    );
    setStockItems(updatedStockItems);
  };

  const stockItemTypes = Object.entries(StockItemType).map(([key, value]) => ({ id: key, name: value }));

  return (
    <SelectInput
      label="Stock Item Type"
      name={`stockItems[${index}].stockItemType`}
      source={`stockItems[${index}].stockItemType`}
      choices={stockItemTypes}
      fullWidth
      onChange={handleChange}
    />
  );
};

const CreateStockEventFields: React.FC<CreateStockEventFieldsProps> = ({
  collectionPartners
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { setValue } = useFormContext();
  const [availableStockItems, setAvailableStockItems] = useState<StockItem[]>([]);
  const [stockItems, setStockItems] = useState<StockEventItem[]>([{ quantity: 0, stockItemId: 0 }]);
  useEffect(() => {
    dataProvider.getList<StockItem>(Resources.StockItems, {
      pagination: {
        page: 1,
        perPage: 500
      },
      sort: {
        field: "name",
        order: "ASC"
      },
      filter: { statuses: ["ACTIVE"] }
    }).then(( response ) => {
      setAvailableStockItems(response.data);
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stock items", { type: "error" });
    });
  }, [dataProvider, notify]);

  const handleAddStockItem = () => {
    setStockItems((prevStockItems) => [...prevStockItems, { quantity: 0, stockItemId: 0 }]);
  };

  const handleRemoveStockItem = (index: number) => {
    setStockItems((prevStockItems) => {
      const updatedStockItems = prevStockItems.filter((_, i) => i !== index);
      setValue("stockItems", updatedStockItems);
      return updatedStockItems;
    });
  };
  return (<>
    <CollectionPartnerGroupedSelect
      source="collectionPartnerId"
      formFieldToFilterOut="relatedCollectionPartnerId"
      collectionPartners={collectionPartners}
      onlyForTransferType={false}
    />
    <SelectInput source="type" fullWidth validate={[required()]} choices={STOCK_EVENT_TYPES_FOR_MANUAL_CREATION}/>
    <CollectionPartnerGroupedSelect
      labelPrefix="Destination"
      source="relatedCollectionPartnerId"
      formFieldToFilterOut="collectionPartnerId"
      collectionPartners={collectionPartners}
      onlyForTransferType={true}
    />
    <AdjustmentReasonInput/>
    <TextInput name="note" source="note" validate={[maxLength(255)]} multiline fullWidth/>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">Stock items:</Typography>
      </Grid>
      {stockItems.map((stockItem, index) => (
        <React.Fragment key={index}>
          <Grid item xs={3} >
            <StockItemTypeInput
              index={index}
              stockItems={stockItems}
              setStockItems={setStockItems} />
          </Grid>
          <Grid item xs={4}>
            <StockItemInput
              availableStockItems={availableStockItems}
              selectedStockItems= {stockItems}
              setStockItems={setStockItems}
              index={index}/>
          </Grid>
          <Grid item xs={3}>
            <QuantityInput
              index={index}
              selectedStockItems= {stockItems}
              setStockItems={setStockItems} />
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "right", alignItems: "flex-start" }}>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => handleRemoveStockItem(index)}
            >
              Remove
            </Button>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <Button variant="outlined"
          endIcon={<AddIcon />}
          onClick={handleAddStockItem}>
          Add Stock Item
        </Button>
      </Grid>
    </Grid>
  </>);
};
export const CreateStockEventDialog: React.FC<CreateStockEventDialogProps> = ({
  onClose,
  onConfirm,
  isProcessing,
  isOpened,
  collectionPartners
}) => {
  return <Dialog
    fullWidth
    maxWidth="md"
    open={isOpened}
    onClose={onClose}
  >
    <DialogTitle>Create Stock Event</DialogTitle>
    <DialogContent>
      <Grid item xs={12}>
        <SimpleForm toolbar={<StockEventCreationToolbar handleClose={onClose}
          isSaveButtonDisabled={isProcessing}/>}
        onSubmit={onConfirm}>
          <CreateStockEventFields collectionPartners={collectionPartners}/>
        </SimpleForm>
      </Grid>
    </DialogContent>
  </Dialog>;
};