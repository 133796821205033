import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  fetchUtils,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Options,
  SortPayload,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "react-admin";
import streamSaver from "streamsaver";
import { WritableStream } from "web-streams-polyfill/ponyfill";

import authTokenStorage from "../auth/auth-token-storage";
import { GetListQueryBuilder } from "./get-list-query-builder";

export enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE"
}

const buildAuthHeaders = (): Headers => {
  const headers = new Headers({ Accept: "application/json" });
  const authToken = authTokenStorage.get();
  if (authToken) {
    headers.set("x-access-token", authToken.token);
  }
  return headers;
};

const httpClient = (url: string, options: Options = {}) => {
  options.headers = buildAuthHeaders();
  return fetchUtils.fetchJson(url, options);
};

export type UploadFileResponse = {
  data: any,
  ok: boolean,
  status: number
};

export type MetaParams = {
  queryParams?: string[][] | Record<string, string> | string | URLSearchParams
};

const buildUrl = (url: string, params: any) => {
  const meta = params.meta as MetaParams;
  if (meta?.queryParams) {
    const queryParams = new URLSearchParams(meta.queryParams).toString();
    url += "?" + queryParams;
  }

  return url;
};

export default (baseUrl: string): DataProvider => {
  return {
    create(resource: string, params: CreateParams): Promise<CreateResult> {
      return httpClient(buildUrl(`${baseUrl}/${resource}`, params), {
        method: HTTP_METHOD.POST,
        body: JSON.stringify(params.data)
      }).then((response) => ({ data: response.json }));
    },

    delete(resource: string, params: DeleteParams): Promise<DeleteResult> {
      return httpClient(`${baseUrl}/${resource}/${params.id}`, {
        method: HTTP_METHOD.DELETE
      }).then((response) => ({ data: response.json }));
    },

    deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
      return httpClient(`${baseUrl}/${resource}`, {
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify({ ids: params.ids })
      }).then(() => ({ data: params.ids }));
    },

    getList(resource: string, params: GetListParams): Promise<GetListResult> {
      const getListQuery = new GetListQueryBuilder()
        .withPagination(params.pagination)
        .withSort(params.sort)
        .withFilter(params.filter)
        .build();
      return httpClient(`${baseUrl}/${resource}?${getListQuery}`,
        {
          method: HTTP_METHOD.GET
        }).then((response) => {
        const responseData = response.json;

        const result = {
          data: responseData.content || responseData.data,
          total: responseData.totalElements,
          pageInfo: responseData.pageInfo
        };

        return result;
      });
    },

    getMany(resource: string, params: GetManyParams): Promise<GetManyResult> {
      return httpClient(`${baseUrl}/${resource}/list`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ ids: params.ids })
      }).then((response) => response.json);
    },
    getManyWithHierarchy(
      resource: string,
      params: GetManyParams
    ): Promise<GetManyResult> {
      return httpClient(`${baseUrl}/${resource}/list`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ includeLocationsHierarchy: true, ids: params.ids })
      }).then((response) => response.json);
    },
    getManyReference(): Promise<GetManyReferenceResult> {
      throw new Error("Not supported!");
    },

    getManyByUrl(url: string) {
      return httpClient(`${baseUrl}/${url}`, {
        method: HTTP_METHOD.GET
      }).then((response) => ({ data: response.json }));
    },

    getManyByUrlWithBody(url: string, body: any) {
      return httpClient(`${baseUrl}/${url}`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body)
      }).then((response) => ({ data: response.json }));
    },

    getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
      return httpClient(`${baseUrl}/${resource}/${params.id}`, {
        method: HTTP_METHOD.GET
      }).then((response) => ({ data: response.json }));
    },

    update(resource: string, params: UpdateParams): Promise<UpdateResult> {
      return httpClient(buildUrl(`${baseUrl}/${resource}/${params.id}`, params), {
        method: HTTP_METHOD.PUT,
        body: JSON.stringify(params.data)
      }).then((response) => ({ data: response.json }));
    },

    updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResult> {
      return httpClient(`${baseUrl}/${resource}`, {
        method: params.meta?.httpMethod || HTTP_METHOD.PUT,
        body: JSON.stringify({
          ids: params.ids,
          ...params.data
        })
      }).then(() => ({ data: [] }));
    },

    createManyByUrlWithBody: (url: string, body: any) => {
      return httpClient(`${baseUrl}/${url}`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body)
      }).then((response) => response.json);
    },

    updateManyByUrlWithBody: (url: string, body: any) => {
      return httpClient(`${baseUrl}/${url}`, {
        method: HTTP_METHOD.PUT,
        body: JSON.stringify(body)
      }).then((response) => response.json);
    },

    signIn: (email: string, password: string) => {
      return httpClient(`${baseUrl}/users/signin`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ email, password })
      }).then((response) => response.json);
    },

    signUp: (email: string, password: string) => {
      return httpClient(`${baseUrl}/users/signup`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ email, password })
      }).then((response) => ({ data: response.json }));
    },

    setPassword: (password: string, token: string) => {
      return httpClient(`${baseUrl}/users/set-password`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ password, token })
      }).then((response) => ({ data: response.json }));
    },

    resetPassword: (email: string) => {
      return httpClient(`${baseUrl}/users/reset-password`, {
        method: HTTP_METHOD.POST,
        body: JSON.stringify({ email })
      }).then((response) => ({ data: response.json }));
    },

    uploadFile: async (resource: string, file: File): Promise<UploadFileResponse> => {
      const headers = buildAuthHeaders();
      const formData = new FormData();
      formData.append("name", file?.name);
      formData.append("file", file);

      const response = await fetch(`${baseUrl}/${resource}`, {
        method: HTTP_METHOD.POST,
        headers,
        body: formData
      });

      const result = {
        data: {},
        ok: response.ok,
        status: response.status
      };
      try {
        result.data = await response.json();
        return result;
      } catch (e) {
        return result;
      }
    },

    exportFile: (resource: string, filter: any, sort: SortPayload) => {
      const headers = buildAuthHeaders();
      headers.set("Content-Type", "application/json");

      const getListQuery = new GetListQueryBuilder()
        .withSort(sort)
        .withFilter(filter)
        .build();

      return fetch(`${baseUrl}/${resource}/export?${getListQuery}`, {
        method: HTTP_METHOD.GET,
        headers: headers
      })
        .then((response) => {
        // If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
          if (!window.WritableStream) {
            // eslint-disable-next-line import/no-named-as-default-member
            streamSaver.WritableStream = WritableStream;
            window.WritableStream = WritableStream;
          }

          // eslint-disable-next-line import/no-named-as-default-member
          const fileStream = streamSaver.createWriteStream(`${resource}.csv`);

          // More optimized
          const readableStream = response.body;
          if (readableStream?.pipeTo) {
            return readableStream.pipeTo(fileStream);
          }

          const writer = fileStream.getWriter();

          // eslint-disable-next-line promise/always-return
          const reader = response.body?.getReader();
          // @ts-ignore
          // eslint-disable-next-line promise/no-nesting
          const pump = () => reader?.read()
            .then((res) => res.done
              ? writer.close()
              // eslint-disable-next-line promise/no-nesting
              : writer.write(res.value).then(pump));

          pump();
        })
        .then(() => ({ data: {} }));
    }
  };
};
