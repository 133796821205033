import React, { useCallback, useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { useNotify } from "ra-core";
import { useDataProvider, useShowController } from "react-admin";
import { Link } from "react-router-dom";

import { useUsers } from "../../../components/user/use-users";
import { UserRoles } from "../../../core/providers/auth/roles";
import { FullCustomer } from "../../../utils/commons";
import {
  enumChipRenderer
} from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { AssignDeviceButton } from "../../contracts/actions/AssignDeviceButton";
import { CancelContractButton } from "../../contracts/actions/CancelContractButton";
import { ReactivateContractButton } from "../../contracts/actions/ReactivateContractButton";
import { RepossessButton } from "../../contracts/actions/RepossessButton";
import { SwapDeviceButton } from "../../contracts/actions/SwapDeviceButton";
import ContractOverview from "../../contracts/ContractOverview";
import {
  Contract,
  ContractStatus,
  contractStatusChipColors
} from "../../contracts/types";
import { CustomerTabProps, GetContractsResponse } from "../types";

const isNonUtilityContractFullyPaid = (contract: Contract): boolean => {
  return contract.totalAmount <= contract.paidAmount && contract.type === "NON_UTILITY";
};

const isMoreThanDaysAgo = (dateString: string, days: number): boolean => {
  const date = moment(dateString);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(date, "days");
  return differenceInDays > days;
};

export const CustomerContractsTab: React.FC<CustomerTabProps> = ({
  customerId
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record: customer } = useShowController<FullCustomer>();
  const [contracts, setContracts] = useState<Contract[]>();
  const [processingContract, setProcessingContract] = useState<number | undefined>();
  const { users } = useUsers();
  const [isContractsLoading, setContractsLoading] = useState(false);
  const { hasAccess } = useCheckAccess([ UserRoles.ROLE_SUPAMOTO_ADMIN ]);

  const fetchContracts = useCallback(() => {
    setContractsLoading(true);
    return dataProvider.getManyByUrl(`customers/${customerId}/contracts`)
      .then((response: GetContractsResponse) => {
        setContracts(response.data);
        return response;
      })
      .catch(() => {
        notify("Error: failed to load customer contracts", { type: "error", autoHideDuration: 5000 });
      }).finally(() => {
        setContractsLoading(false);
      });
  }, [dataProvider, customerId, notify]);

  const onContractChange = (contractId: number) => {
    setProcessingContract(contractId);
    fetchContracts()
      .finally(() => {
        setProcessingContract(undefined);
      });
  };

  useEffect(() => {
    if (!customerId) return;
    fetchContracts();

  }, [customerId, fetchContracts]);

  if ((isContractsLoading && !processingContract) || !customer) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <CircularProgress size={30}/>Fetching
      </Box>
    );
  }

  if (contracts && contracts.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">No contracts found</Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {contracts?.map((contract) => (
        <Accordion
          key={contract.id}
          defaultExpanded={contract.status === "ACTIVE"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">Contract #{contract.id} {enumChipRenderer(contract.status, ContractStatus, contractStatusChipColors)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              { processingContract === contract.id && <Grid item
                xs={12}
                sx={{
                  zIndex: 9999,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)"
                }}
              >
                <CircularProgress/>
              </Grid>}
              <ContractOverview
                contract={contract}
                customer={customer} 
                users={users}
              />
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ px: 2, py: 1 }}>
                  {customer && (
                    <RepossessButton
                      contract={contract}
                      disabled={!hasAccess || contract.status !== "ACTIVE" || isNonUtilityContractFullyPaid(contract)}
                      onContractChange={onContractChange}
                    />)}
                </Box>
                <Box sx={{ px: 2, py: 1 }}>
                  <CancelContractButton
                    disabled={!hasAccess || contract.status !== "ACTIVE" ||
                        (contract.paidAmount - contract.downPayment) > 0
                        || isNonUtilityContractFullyPaid(contract)
                        || isMoreThanDaysAgo(contract.timeCreated, 7)
                    }
                    contract={contract}
                    onContractChange={onContractChange}
                  />
                </Box>
                {contract.details.some((detail) => detail.offer.offerType === "STOVE" && !detail.instanceId) ? (
                  <Box sx={{ px: 2, py: 1 }}>
                    <AssignDeviceButton
                      contract={contract}
                      onContractChange={onContractChange}
                    />
                  </Box>
                ) : (
                  <Box sx={{ px: 2, py: 1 }}>
                    <SwapDeviceButton
                      customer={customer}
                      contract={contract}
                      disabled={!hasAccess || !customer || contract.status !== "ACTIVE"
                              || !contract.details.some((detail) => detail.instanceId !== undefined)}
                      onContractChange={onContractChange}
                    />
                  </Box>
                )}
                {
                  contract.status !== "ACTIVE" && contract.status !== "CANCELLED" && (
                    <Box sx={{ px: 2, py: 1 }}>
                      <ReactivateContractButton
                        contract={contract}
                        disabled={isMoreThanDaysAgo(contract.defaultDate, 15)}
                        onContractChange={onContractChange}
                      />
                    </Box>
                  )
                }
                <Box sx={{ px: 2, py: 1 }}>
                  <Button
                    component={Link}
                    to={`/contracts/${contract.id}/show`}
                    startIcon={<HistoryIcon />}
                  >
                    View History
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
