import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useDataProvider, useNotify, useShowController } from "react-admin";
import { Chrono } from "react-chrono";

import { User } from "../../../components/user/types";
import { Resources } from "../../../resources";
import { AllStoveInactivationReason, Device } from "../../../utils/commons";
import { usernameRenderer } from "../../../utils/field-renderers";
import { ContractProducts } from "../ContractProducts";
import {
  AnyContractHistory, AssignContractHistory,
  Contract,
  ContractHistoryAction,
  CreateContractHistory,
  InactiveContractHistory,
  RepossessContractHistory,
  SwapDeviceContractHistory
} from "../types";

const action = (item: any) => ({
  id: item.id,
  eventTime: item.eventTime,
  action: item.action,
  contributedBy: item.contributedBy,
  ...item
});

const ActiveContractEvent: React.FC<CreateContractHistory & { instanceIdToDeviceIdMap: Record<number, string> }> = ({
  payload,
  instanceIdToDeviceIdMap }) => (
  <>
    <Typography variant="body1">Contract details</Typography>
    <ContractProducts details={payload.details} instanceIdToDeviceIdMap={instanceIdToDeviceIdMap} />
  </>
);

const SwapDeviceContractEvent: React.FC<SwapDeviceContractHistory &
    { instanceIdToDeviceIdMap: Record<number, string> }> = ({
      payload,
      instanceIdToDeviceIdMap }) => (
      <>
        <Typography variant="body1">
          Swapped to new device with Device number: {" "}
          {instanceIdToDeviceIdMap[payload.newInstanceId] || "Unknown Device"}
        </Typography>
        <Typography variant="body1">Reason: {AllStoveInactivationReason[payload.reason]}</Typography>
      </>
    );

const AssignDeviceContractEvent: React.FC<AssignContractHistory &
    { instanceIdToDeviceIdMap: Record<number, string> }> = ({
      payload,
      instanceIdToDeviceIdMap }) => (
      <>
        <Typography variant="body1">
        Assign new Device device with number: {" "}
          {instanceIdToDeviceIdMap[payload.newInstanceId] || "Unknown Device"}
        </Typography>
      </>
    );

const RepossessContractEvent: React.FC<RepossessContractHistory &
    { instanceIdToDeviceIdMap: Record<number, string> }> = ({
      payload,
      instanceIdToDeviceIdMap }) => (
      <>
        <Typography variant="body1">Repossessed products: </Typography>
        <ContractProducts details={payload.repossessedDetails} instanceIdToDeviceIdMap={instanceIdToDeviceIdMap} />
        <Box></Box>
        <Typography variant="body1">Reason: {AllStoveInactivationReason[payload.reason]}</Typography>
      </>
    );

const InactiveEvent: React.FC<InactiveContractHistory> = ({ payload }) => (
  <>
    <Typography variant="body1">Reason: {AllStoveInactivationReason[payload.reason]}</Typography>
  </>
);
export const ContractHistoryTab: React.FC = () => {
  const { record: contract } = useShowController<Contract>();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [users, setUsers] = useState<User[]>([]);
  const [isUsersLoading, setUsersLoading] = useState(false);
  const [isHistoriesLoading, setHistoriesLoading] = useState(false);
  const [contractHistories, setContractHistories] = useState<AnyContractHistory[]>([]);
  const [stoves, setStoves] = useState<Record<number, string> | null>(null);

  useEffect(() => {
    if (!contract) return;

    setHistoriesLoading(true);
    dataProvider.getManyByUrl(`contracts/${contract.id}/histories`)
      .then((response: { data: AnyContractHistory[] }) => {
        setContractHistories(response.data.map(action));
        setHistoriesLoading(false);
        return response;
      })
      .catch(() => setHistoriesLoading(false));
  }, [contract, dataProvider, notify]);

  useEffect(() => {
    if (!contractHistories.length) return;
    setUsersLoading(true);
    const userIds = Array.from(new Set(contractHistories.map((item) => item.contributedBy)));
    dataProvider.getMany<User>(Resources.Users, { ids: userIds })
      .then((response) => {
        setUsers(response.data);
        setUsersLoading(false);
        return response;
      })
      .catch(() => {
        setUsersLoading(false);
        notify("Error: failed to retrieve users", { type: "error" });
      });

    const instanceIds = contractHistories.reduce((ids, item) => {
      if ((item.action === "CREATE" || item.action === "REACTIVATE") && (item as CreateContractHistory).payload) {
        ids.push(...(item as CreateContractHistory).payload.details
          .map((detail) => detail.instanceId)
          .filter((instanceId): instanceId is number => instanceId !== undefined));
      } else if (item.action === "ASSIGN_DEVICE" && (item as AssignContractHistory).payload) {
        const newInstanceId = (item as AssignContractHistory).payload.newInstanceId;
        if (newInstanceId !== undefined) {
          ids.push(newInstanceId);
        }
      } else if (item.action === "SWAP_DEVICE" && (item as SwapDeviceContractHistory).payload) {
        const newInstanceId = (item as SwapDeviceContractHistory).payload.newInstanceId;
        if (newInstanceId !== undefined) {
          ids.push(newInstanceId);
        }
      }
      return ids;
    }, [] as number[]);

    if (instanceIds.length) {
      dataProvider.getMany<Device>(Resources.Devices, { ids: instanceIds })
        .then((response: { data: any[] }) => {
          const stoveMap = response.data.reduce((map, stove) => {
            map[stove.id] = stove.deviceId;
            return map;
          }, {} as Record<number, string>);
          setStoves(stoveMap);
          return response;
        })
        .catch(() => {
          notify("Error: failed to retrieve stoves", { type: "error" });
        });
    } else {
      setStoves({});
    }
  }, [contractHistories, dataProvider, notify]);

  if (isHistoriesLoading || isUsersLoading || stoves === null) return null;

  return (
    <Chrono
      mode="VERTICAL"
      disableToolbar="true"
      showNavigation={false}
      theme={{
        primary: "gray",
        secondary: "white",
        cardBgColor: "white",
        titleColor: "black",
        titleColorActive: "secondary"
      }}
      items={contractHistories.map((item) => {
        const user = users.find((user) => user.id === item.contributedBy);
        return {
          title: `${ContractHistoryAction[item.action]} by ${user ? usernameRenderer(user) : "Unknown User"}
                  ${new Date(item.eventTime).toLocaleString()}`,
          cardDetailedText: (
            <>
              {(item.action === "CREATE" || item.action === "REACTIVATE") && (item as CreateContractHistory).payload && (
                <ActiveContractEvent {...(item as CreateContractHistory)} instanceIdToDeviceIdMap={stoves}/>
              )}
              {(item.action === "CANCEL" || item.action === "WRITE_OFF") && (item as InactiveContractHistory).payload && (
                <InactiveEvent {...(item as InactiveContractHistory)} />
              )}
              {item.action === "SWAP_DEVICE" && (item as SwapDeviceContractHistory).payload && (
                <SwapDeviceContractEvent {...(item as SwapDeviceContractHistory)} instanceIdToDeviceIdMap={stoves} />
              )}
              {item.action === "REPOSSESS" && (item as RepossessContractHistory).payload && (
                <RepossessContractEvent {...(item as RepossessContractHistory)} instanceIdToDeviceIdMap={stoves} />
              )}
              {item.action === "ASSIGN_DEVICE" && (item as AssignContractHistory).payload && (
                <AssignDeviceContractEvent {...(item as AssignContractHistory)} instanceIdToDeviceIdMap={stoves} />
              )}
            </>
          )
        };
      })}
    />
  );
};
