import { CountryHavingObject } from "../../utils/commons";
import { Order } from "../orders/types";

export enum PaymentTransactionType {
  REPLENISHMENT = "Replenishment",
  WITHDRAWAL = "Withdrawal",
  REVERT = "Revert"
}

export type PaymentTransaction = {
  id: number,
  activeAssignment: boolean,
  amount: number,
  currency: string,
  customerId: number,
  operationType: PaymentTransactionType,
  transactionId: string,
  sourceType?: "PAYMENT" | "ORDER",
  source?: Order | Payment
};

export type Payment = {
  id: number,
  customerId?: number,
  telcoTransactionId: string,
  createdBy: number,
  telco: string,
  amount: number,
  currency: string,
  paymentReference: string,
  payer: string,
  bank: string,
  isRefunded: boolean,
  note: string,
  refundedBy: number,
  timePaid: string,
  transactions: PaymentTransaction[]
} & CountryHavingObject;

export enum ZMTelcos {
  airtel = "Airtel",
  mtn = "Mtn",
  bank = "Bank"
}

export enum MWTelcos {
  airtel = "Airtel",
  standard_bank_mw = "Standard Bank"
}

export enum MZTelcos {
  movitel = "Movitel",
  mpesa = "M-Pesa"
}

export const AllTelcos = {
  ...ZMTelcos,
  ...MWTelcos,
  ...MZTelcos
};