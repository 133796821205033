import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import AddLinkIcon from "@mui/icons-material/AddLink";
import {
  Alert,
  Autocomplete,
  Button, CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { debounce, isEmpty } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { useForm } from "../../../components/form/use-form";
import { FullStove } from "../../stoves/StoveShow";
import { Contract } from "../types";

type AssignDeviceButtonProps = {
  contract: Contract,
  onContractChange: (contractId: number) => void
};

const initialFormState: AssignDeviceFormState = {
  nativeId: null
};

type AssignDeviceFormState = {
  nativeId: number | null
};

export const AssignDeviceButton: React.FC<AssignDeviceButtonProps> = ({
  contract,
  onContractChange
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<AssignDeviceFormState>(initialFormState, yup.object({}));

  const [isAssigning, setIsAssigning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stoves, setStoves] = useState<readonly FullStove[]>([]);
  const [uniqueDeviceIds, setUniqueDeviceIds] = useState<string[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedStove, setSelectedStove] = useState<FullStove | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchStoves = useCallback(debounce((value: string) => {
    setIsLoading(true);

    dataProvider.getList("stoves", {
      pagination: {
        page: 1,
        perPage: 10
      },
      sort: {
        field: "id",
        order: "DESC"
      },
      filter: {
        queryText: [value]
      }
    }).then((response) => {
      setStoves(response.data);
      setUniqueDeviceIds(Array.from(new Set(response.data.map((stove) => stove.deviceId))));
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stoves", { type: "error" });
    }).finally(() => {
      setIsLoading(false);
    });
  }, 500), [dataProvider, notify]);

  useEffect(() => {
    if (isEmpty(inputValue) || inputValue.length < 3) return;
    fetchStoves(inputValue);
  }, [inputValue, fetchStoves]);

  const handleCancel = () => {
    setIsFormOpen(false);
    setSelectedStove(undefined);
  };
  const handleConfirm = () => {
    setIsAssigning(true);
    dataProvider.updateManyByUrlWithBody(`contracts/${contract.id}/assign`, {
      nativeId: selectedStove?.nativeId
    }).then((response: any) => {
      notify("Device has been successfully assigned");
      onContractChange(contract.id);
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to assign device", { type: "error" });
    }).finally(() => setIsAssigning(false));
  };

  const onChange = (event: SyntheticEvent, value: string, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption" && value) {
      const stove = stoves.find((stove) => stove.deviceId === value
              && stove.status === "ACTIVE")
          || stoves.find((stove) => stove.deviceId === value);

      setSelectedStove(stove);
      setInputValue(value.toString());
    }
  };
  
  return (
    <>
      <Tooltip title="Assign Device: Available only for existing stove contracts without stove.">
        <span>
          <Button
            color="primary"
            variant={"text"}
            onClick={() => setIsFormOpen(true)}
            startIcon={<AddLinkIcon/>}>
            {"Assign Device"}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Assign device</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  You are trying to assign a stove to an existing stove contract without a stove.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  loading={isLoading}
                  inputValue={inputValue}
                  options={uniqueDeviceIds}
                  getOptionLabel={(deviceId: string) => `${deviceId}`}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Device ID *"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              {selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE" &&
                    (
                      <Grid item xs={12}>
                        <Alert severity="warning">
                            You cannot assign
                          <Link to={`/stoves/devices/${selectedStove?.instanceId}/show`}>
                            {" " + selectedStove.deviceId}
                          </Link>. It is already assigned to a customer.
                        </Alert>
                      </Grid>
                    )
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>
                Cancel
            </Button>
            <ProcessingButton
              onClick={handleConfirm}
              disabled={!form.isValid ||
                      Boolean(selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE")}
              isProcessing={isAssigning}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};