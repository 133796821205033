import * as React from "react";

import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { isNil } from "lodash-es";
import { Link } from "react-router-dom";

import { ContractDetail } from "./types";

interface Props {
  details: ContractDetail[],
  instanceIdToDeviceIdMap: Record<number, string>
}

export const ContractProducts: React.FC<Props> = ({
  details = [],
  instanceIdToDeviceIdMap
}) => {
  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ pt: 0 }}>Product</TableCell>
          <TableCell sx={{ pt: 0 }}>Device ID</TableCell>
          <TableCell sx={{ pt: 0 }}>Condition Type</TableCell>
          <TableCell sx={{ pt: 0 }}>Discount</TableCell>
          <TableCell sx={{ pt: 0 }}>Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {details.map((product: ContractDetail) => (
          <TableRow key={product.id}>
            <TableCell>{product.offer.name}</TableCell>
            <TableCell>
              {product.instanceId ? (
                <Link to={`/stoves/devices/${product.instanceId}/show`} target="_blank">
                  {instanceIdToDeviceIdMap[product.instanceId] || "Unknown Device"}
                </Link>
              ) : "-"}
            </TableCell>
            <TableCell>
              {!isNil(product.conditionType) ? (
                product.conditionType === "NEW" ? (
                  <Chip label="New" color="success" variant="outlined" clickable size="small" />
                ) : (
                  <Chip label="Pre-Owned" color="warning" variant="outlined" clickable size="small" />
                )
              ) : "-"}
            </TableCell>
            <TableCell>{product.discount?.name || "-"}</TableCell>
            <TableCell>{product.quantity}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>;
};