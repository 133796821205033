import React from "react";

import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField
} from "react-admin";

import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { User, UserAccessType, UserStatus } from "../../components/user/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { enumRenderer } from "../../utils/field-renderers";
import { USER_ROLES } from "./constants";

export const roleRenderer = (record: User) => USER_ROLES[record.role as UserRoles] || record.role;

const filters = [
  baseMultilineTextInputFilter({
    source: "username",
    label: buildLabel({ label: "Email", Icon: CustomFilterIcon })
  }),
  baseMultilineTextInputFilter({
    source: "fullName",
    label: buildLabel({ label: "User name", Icon: CustomFilterIcon })
  }),
  enumSelectFilter({
    source: "roles",
    enumObj: USER_ROLES,
    label: buildLabel({ label: "Role", Icon: CustomFilterIcon }),
    alwaysOn: true
  }),
  enumSelectFilter({
    source: "statuses",
    enumObj: UserStatus,
    label: buildLabel({ label: "Status", Icon: CustomFilterIcon })
  })
];

export const UserList: React.FC = () => {

  return (
    <List
      filters={filters}
      actions={<PresetListActions/>}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="username" label="Email"/>
        <TextField source="firstName" />
        <TextField source="lastName"/>
        <FunctionField render={roleRenderer} label="Role" sortBy="role"/>
        <FunctionField render={(record: User) => enumRenderer(record.accessType, UserAccessType)}
          label="Access type"/>
        <FunctionField render={(record: User) => enumRenderer(record.status, UserStatus)}
          label="Status"/>
        <DateField source="timeCreated" showTime/>
        <ReferenceField label="Company Name" reference={Resources.Companies} source="company.id">
          <TextField source="name"/>
        </ReferenceField>
        <EditButton/>
      </Datagrid>
    </List>
  );
};
