import React, { useEffect, useState } from "react";

import { Chip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import {
  DatagridConfigurable, DateField,
  FunctionField,
  ListContextProvider, ReferenceField,
  TextField,
  useDataProvider
} from "react-admin";

import { User } from "../../../components/user/types";
import { Resources } from "../../../resources";
import { EnumChipColors, usernameRenderer } from "../../../utils/field-renderers";
import { Payment, PaymentTransaction } from "../../payments/types";

type CustomerTransactionsTabProps = {
  customerId?: number
};

type GetTransactionsResponse = {
  data: PaymentTransaction[]
};

type PaymentTransactionWithBalance = PaymentTransaction & { balanceAfter: number };

const EmptyData = () => <Alert severity="info">No transactions found</Alert>;

const operationTypeColors: Record<string, EnumChipColors> = {
  POSITIVE: "success",
  NEGATIVE: "error"
};

const operationTypeRenderer = (record: PaymentTransaction) => {
  if (!record.operationType) return null;

  const chipColor = record.amount >= 0 ? operationTypeColors.POSITIVE : operationTypeColors.NEGATIVE;

  return (
    <Chip
      label={record.operationType}
      color={chipColor}
      variant="outlined"
      size="small"
    />
  );
};
export const CustomerTransactionsTab: React.FC<CustomerTransactionsTabProps> = ({
  customerId
}) => {
  const dataProvider = useDataProvider();
  const [transactions, setTransactions] = useState<PaymentTransaction[]>([]);
  const [isTransactionsLoading, setTransactionsLoading] = useState(false);

  useEffect(() => {
    if (!customerId) return;

    setTransactionsLoading(true);
    dataProvider.getManyByUrl(`customers/${customerId}/transactions`)
      .then((response: GetTransactionsResponse) => {
        setTransactions(response.data);
        setTransactionsLoading(false);
        return response;
      })
      .catch(() => {
        setTransactionsLoading(false);
      });

  }, [customerId, dataProvider]);

  let runningBalance = 0;
  const transactionsWithBalance: PaymentTransactionWithBalance[] = transactions.map((transaction) => {
    runningBalance += transaction.amount;
    return { ...transaction, balanceAfter: runningBalance };
  });

  const context = {
    data: transactionsWithBalance,
    total: transactionsWithBalance.length,
    page: 1,
    perPage: transactionsWithBalance.length,
    filterValues: {},
    sort: {}
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          isTransactionsLoading ?
            <>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
            </> :
            <ListContextProvider value={context as ListControllerResult}>
              <DatagridConfigurable empty={<EmptyData/>}>
                <TextField source="id"/>
                <TextField source="transactionId"/>
                <FunctionField
                  label="Amount"
                  source="amount"
                  sortBy="amount"
                  render={(record: PaymentTransaction) => {
                    const formattedAmount =
                              record.amount < 0
                                ? `(${Math.abs(record.amount).toFixed(2)})`
                                : record.amount.toFixed(2);
                    return `${formattedAmount} ${record.currency}`;
                  }}
                />
                <FunctionField
                  label="Balance after transaction"
                  render={(record: PaymentTransactionWithBalance) => {
                    const formattedBalance =
                              record.balanceAfter < 0
                                ? `(${Math.abs(record.balanceAfter).toFixed(2)})`
                                : record.balanceAfter.toFixed(2);
                    return `${formattedBalance} ${record.currency}`;
                  }}
                />
                <FunctionField
                  label="Operation Type"
                  source="operationType"
                  render={operationTypeRenderer}
                />
                <FunctionField
                  label="Order Id"
                  render={(record: PaymentTransaction) => record.sourceType === "ORDER" ? record.source?.id : "N/A"}
                />
                <FunctionField
                  label="Payment Id"
                  render={(record: PaymentTransaction) => record.sourceType === "PAYMENT" ? (record.source as Payment)?.telcoTransactionId : "N/A"}
                />
                <ReferenceField
                  label="Created by"
                  reference={Resources.Users}
                  source="createdBy"
                  sortable={false}
                  link={false}>
                  <FunctionField render={(user: User) => usernameRenderer(user)}/>
                </ReferenceField>
                <DateField
                  source="operationDate"
                  label="Operation date"
                  showTime/>
              </DatagridConfigurable>
            </ListContextProvider>
        }
      </Grid>
    </Grid>
  );
};
