import React from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { CountryCurrency, FullCustomer } from "../../utils/commons";
import { DateTimeFormat, formatDate, formatDateTime } from "../../utils/date-time-utils";
import {
  contractDuration,
  enumChipRenderer,
  enumRenderer,
  usernameRenderer
} from "../../utils/field-renderers";
import { ContractProducts } from "./ContractProducts";
import PaymentSchedule from "./PaymentSchedule";
import {
  Contract,
  ContractPaymentStatus,
  contractPaymentStatusChipColors,
  ContractStatus,
  contractStatusChipColors,
  ContractType
} from "./types";

interface ContractOverviewProps {
  contract: Contract,
  customer: FullCustomer,
  users: any[]
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3
  }
}));

const ContractOverview: React.FC<ContractOverviewProps> = ({
  contract,
  customer,
  users
}) => {
  const instanceIdToDeviceIdMap = customer.stoves.reduce((map, stove) => {
    map[stove.instanceId] = stove.deviceId;
    return map;
  }, {} as Record<number, string>);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Contract information</Typography>
      </Grid>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ pl: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>Number:</Grid>
              <Grid item xs={6}>{contract.id}</Grid>

              <Grid item xs={6}>Duration:</Grid>
              <Grid item xs={6}>{contractDuration(contract.duration)}</Grid>

              <Grid item xs={6}>Type:</Grid>
              <Grid item xs={6}>{enumRenderer(contract.type, ContractType)}</Grid>

              <Grid item xs={6}>Contract Status:</Grid>
              <Grid item xs={6}>
                {enumChipRenderer(contract.status, ContractStatus, contractStatusChipColors)}
              </Grid>

              <Grid item xs={6}>Payment Status:</Grid>
              <Grid item xs={6}>{enumChipRenderer(
                contract.totalAmount > contract.paidAmount ? "INCOMPLETE" : "COMPLETED",
                ContractPaymentStatus, contractPaymentStatusChipColors)}</Grid>

              <Grid item xs={6}>Effective Date:</Grid>
              <Grid item xs={6}>{formatDate(contract.effectiveDate, DateTimeFormat.CUSTOM_DATE)}</Grid>

              <Grid item xs={6}>Created Date:</Grid>
              <Grid item xs={6}>{formatDateTime(contract.timeCreated)}</Grid>

              {contract.defaultDate && (
                <>
                  <Grid item xs={6}>Default Date:</Grid>
                  <Grid item xs={6}>{formatDateTime(contract.defaultDate)}</Grid>
                </>
              )}

              <Grid item xs={6}>Created By:</Grid>
              <Grid item xs={6}>{usernameRenderer(users.find((user) => user.id === contract.createdBy))}</Grid>
            </Grid>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Grid item xs={12} sm={6}>
          <ContractProducts
            details={contract.details}
            instanceIdToDeviceIdMap={instanceIdToDeviceIdMap}
          />
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Contract progress</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={(contract.paidAmount / contract.totalAmount) * 100}
                  sx={{ my: 2 }}
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                >{`${Math.round((contract.paidAmount / contract.totalAmount) * 100)}%`}</Typography>
              </Box>
            </Box>

            <Typography variant="body2">
              Paid <span style={{ fontWeight: 700 }}>{contract.paidAmount}{" "}{CountryCurrency[customer?.country]}</span> out of {" "}
              <span style={{ fontWeight: 700 }}>{contract.totalAmount}{" "}{CountryCurrency[customer?.country]}</span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <PaymentSchedule
              customer={customer}
              downPayment={contract.downPayment}
              duration={contract.duration}
              totalAmount={contract.totalAmount}
              effectiveDate={contract.effectiveDate}
              paidAmount={contract.paidAmount}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
      </Grid>
    </>
  );
};

export default ContractOverview;