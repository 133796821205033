import * as React from "react";

import { Show } from "react-admin";

import { ContractHistoryTab } from "./tabs/ContractHistoryTab";

export const ContractShow: React.FC = () => {

  const CustomerShowLayout = () => {
    return (
      <ContractHistoryTab/>
    );
  };

  return (
  // workaround to force fetch data on every show
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <CustomerShowLayout/>
    </Show>
  );
};
