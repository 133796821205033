import React, { ReactNode, useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";
import { groupBy } from "lodash-es";
import { GetManyResult, useDataProvider, useNotify } from "react-admin";

import { Resources } from "../../../../resources";
import { Customer } from "../../../../utils/commons";
import { Order } from "../../types";
import { CreateIndividualDeliveryRequest, DeliveryDetails } from "../common/common";
import { useCreateDelivery } from "../common/use-create-delivery";
import { IndividualDeliveryForm } from "./IndividualDeliveryForm";

type Props = {
  onConfirm: () => void,
  onClose: () => void,
  orders: Order[]
};

type DeliveryDetailsMapping = Record<number, DeliveryDetails | undefined>;

const initDeliveryDetails = (customerIds: string[]) => {
  return customerIds.reduce((details: DeliveryDetailsMapping, customerId) => {
    details[Number(customerId)] = undefined;
    return details;
  }, {});
};

const mapCustomers = (response: GetManyResult<Customer>) => {
  return response.data.reduce((customers: Record<number, Customer>, customer) => {
    customers[customer.id] = customer;
    return customers;
  }, {});
};

export const IndividualDelivery: React.FC<Props> = ({
  onConfirm,
  onClose,
  orders
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { createDelivery, isLoading: isDeliveryLoading } = useCreateDelivery("individual");
  const [ deliveryForms, setDeliveryForms] = useState<ReactNode[]>([]);
  const [ deliveryDetails, setDeliveryDetails ] = useState<DeliveryDetailsMapping>({});

  const dataChangeCallback = useCallback((customerId: number, isValid: boolean, data: DeliveryDetails) => {
    setDeliveryDetails((prev) => ({
      ...prev,
      [customerId]: isValid ? data : undefined
    }));
  }, []);

  useEffect(() => {
    const customerOrders = groupBy(orders, "customerId");
    const customerIds = Object.keys(customerOrders);
    setDeliveryDetails(initDeliveryDetails(customerIds));

    dataProvider.getManyWithHierarchy(Resources.Customers, {
      ids: customerIds
    }).then((response: GetManyResult<Customer>) => {
      const customers = mapCustomers(response);
      const forms = customerIds.map((customerId) =>
        <IndividualDeliveryForm
          key={customerId}
          orders={customerOrders[customerId]}
          customer={customers[Number(customerId)]}
          dataChangeCallback={dataChangeCallback}
        />
      );
      setDeliveryForms(forms);
      return;
    }).catch(() => {
      notify("Error: failed to retrieve customers' info", { type: "error" });
    });
  }, [dataProvider, orders, notify, dataChangeCallback]);

  const onDialogConfirm = () => {
    const request: CreateIndividualDeliveryRequest = {
      deliveries: Object.values(deliveryDetails) as DeliveryDetails[]
    };
    createDelivery(request, onConfirm);
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>
          To create a delivery task, you need to fill in every required field (marked with *).
          Once all required data is populated, the red cross (<CloseIcon sx={{ fontSize: "16px" }} color={"warning"}/>) will change to the green checkmark (<CheckIcon sx={{ fontSize: "16px" }} color={"success"}/>),
          which means you are good to submit the form.
        </DialogContentText>
        <Box sx={{ mt:1 }}>
          {
            deliveryForms.length ?
              deliveryForms :
              <Box sx={{ width: "100%", height: "200px", position: "relative" }}>
                <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%" }}/>
              </Box>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={Object.values(deliveryDetails).some((details) => details === undefined) || isDeliveryLoading}
          onClick={onDialogConfirm}>
          {isDeliveryLoading ? "Processing" : "Confirm" }
        </Button>
      </DialogActions>
    </>
  );
};
